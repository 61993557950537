<!-- 组件说明 -->
<template>
    <div class="manage" v-loading="loading">
        <div class="ls_flexalign_center search_part">
            <el-select
                v-model="params.groupId"
                placeholder="全部标签"
                size="medium"
            >
                <el-option
                    v-for="dict in courseList"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                />
            </el-select>

            <el-input
                v-model.trim="params.name"
                size="medium"
                style="width: 200px"
                placeholder="请输入学生名称"
            ></el-input>

            <el-button
                size="medium"
                plain
                @click="(params.pageIndex = 1), getList()"
            >
                搜索
            </el-button>
            <div class="ls_flex_1"></div>
            <el-button
                size="medium"
                type="warning"
                @click="$router.push({ path: '/manage/class' })"
            >
                标签管理
            </el-button>
            <el-button size="medium" type="success" @click="getCode">
                添加学生
            </el-button>
        </div>
        <div class="work_list" v-if="total > 0">
            <el-table
                class="pd20"
                ref="multipleTable"
                :data="workList"
                highlight-current-row
                :header-cell-style="{ background: '#FAFAFA' }"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" />

                <el-table-column label="学员" width="240px">
                    <template slot-scope="scope">
                        <img
                            :src="scope.row.avatar | imgFormat"
                            style="
                                height: 60px;
                                width: 60px;
                                margin-right: 10px;
                            "
                        />
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" width="120px">
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属标签">
                    <template slot-scope="scope">
                        <span>{{ scope.row.groupName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="作品数量">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.worksNum
                                    ? scope.row.worksNum + "个"
                                    : "暂无作品"
                            }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span @click="del(scope.row.id)">移出学校</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="total == 0 && !loading" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <div class="page_part ls_flexalign_center">
            <div class="ls_flex">
                <!-- <el-button
                    @click="
                        showBatchClass = true;
                        batchClass = '';
                    "
                >
                    批量改标签
                </el-button>
                <el-button
                    @click="
                        batchStatus = '';
                        showBatchStatus = true;
                    "
                >
                    批量改状态
                </el-button> -->
                <el-button @click="del()">批量移除</el-button>
            </div>
            <div class="ls_flex_1"></div>
            <el-pagination
                v-show="total > 0"
                :current-page="params.pageIndex"
                :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>

        <el-dialog
            title="招生海报"
            :visible.sync="showCode"
            width="410px"
            :close-on-click-modal="false"
        >
            <div class="poster" v-if="showCode">
                <div id="qrcode"></div>
                <div style="text-align: center" v-if="btnShow">
                    <el-button type="success" @click="download">
                        下载海报
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            title="批量修改状态"
            :visible.sync="showBatchStatus"
            width="300px"
            top="10vh"
            :close-on-click-modal="false"
        >
            <div>
                <el-radio v-model="batchStatus" label="0">在读中</el-radio>
                <el-radio v-model="batchStatus" label="1">已毕业</el-radio>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showBatchStatus = false">取消</el-button>
                <el-button type="primary" @click="createBatchStatus(1)">
                    确定
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="批量修改标签"
            :visible.sync="showBatchClass"
            width="300px"
            top="10vh"
            :close-on-click-modal="false"
        >
            <div>
                <el-radio
                    v-model="batchClass"
                    :label="item.id"
                    v-for="(item, index) in courseList.filter(item => {
                        return item.id !== -1;
                    })"
                    :key="index"
                >
                    {{ item.name }}
                </el-radio>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showBatchClass = false">取消</el-button>
                <el-button type="primary" @click="createBatchStatus(2)">
                    确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";

export default {
    data() {
        return {
            btnShow: true,
            params: {
                pageIndex: 1,
                pageSize: 10,
                name: "",
                studyStatus: -1,
                groupId: -1,
                examineStatus: "ALL",
            },
            paramsClass: {
                name: "",
            },
            showBatch: false,
            temp: {
                avatar: "",
                groupId: "",
                className: "",
                hasAssistant: "",
                id: "",
                phoneNumber: "",
                examineStatus: "",
                name: "",
                studentNumber: "",
                studyStatus: "",
                updateTime: "",
            },
            studyStatus: "",
            statusList: [
                {
                    name: "全部状态",
                    value: -1,
                },
                {
                    name: "在读中",
                    value: 0,
                },
                {
                    name: "已毕业",
                    value: 1,
                },
            ],
            auditList: [
                {
                    name: "未审核",
                    value: "WAIT",
                },
                {
                    name: "已通过",
                    value: "ALL",
                },
                {
                    name: "已驳回",
                    value: "REJECT",
                },
            ],
            activeName: 0,
            courseList: [],
            classList: [],
            workList: [],
            classDataList: [],
            total: null,
            totalClass: null,
            loading: false,
            classShow: false,
            className: "",
            classTitle: "",
            groupId: "",
            name: "",
            checkedAll: false,
            showBatchStatus: false,
            showBatchClass: false,
            batchAuditing: "",
            batchStatus: "",
            batchClass: "",
            multipleSelection: [],
            isShowEmpty: false,
            showCode: false,
        };
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        del(id) {
            this.$confirm("移出学生后，学生将无法接收任何学校信息。", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let ids = [];
                if (id) {
                    ids = [id];
                } else {
                    var studentIds = JSON.parse(
                        JSON.stringify(this.multipleSelection)
                    );
                    if (studentIds.length == 0) {
                        this.$message({
                            message: "请先选择学生",
                            type: "warning",
                        });
                        return;
                    }
                    studentIds.forEach(element => {
                        ids.push(element.id);
                    });
                }
                this.$ajax
                    .post("/user/teacher/delete", { ids })
                    .then(result => {
                        this.$message({
                            message: "移除成功",
                            type: "success",
                        });
                        this.getList();
                    });
            });
        },
        download() {
            this.btnShow = false;
            this.$nextTick(() => {
                let shareContent = document.getElementsByClassName("poster")[0];
                const width = shareContent.offsetWidth;
                const height = shareContent.offsetHeight;
                let scale = 1;
                var opts = {
                    windowHeight: height,
                    windowWidth: width,
                    scale: scale,
                    scrollX: 0,
                    scrollY: 0,
                    y: 0,
                    backgroundColor: "transparent", //背景图片透明
                    allowTaint: true, //跨域
                    useCORS: true, //跨域
                };
                html2canvas(shareContent, opts).then(canvas => {
                    let oImg = new Image();
                    oImg = canvas.toDataURL("image/png"); // 导出图片
                    var oA = document.createElement("a");
                    oA.download = "招生海报"; // 设置下载的文件名，默认是'下载'
                    oA.href = oImg;
                    document.body.appendChild(oA);
                    oA.click();
                    oA.remove();
                    this.btnShow = true;
                });
            });
        },
        getCode() {
            let url = "";
            this.$ajax
                .post("/user/teacher/getStudentShareCode", {})
                .then(result => {
                    url = result.data;
                    this.showCode = true;
                    this.$nextTick(() => {
                        new QRCode("qrcode", {
                            width: 115, // 设置宽度，单位像素
                            height: 115, // 设置高度，单位像素
                            text: url, // 设置二维码内容或跳转地址
                        });
                    });
                });
        },
        getList() {
            this.loading = true;
            this.$ajax
                .post("/user/student/my/getList", this.params)
                .then(response => {
                    var pageTotal = Math.ceil(
                        response.total / this.params.pageSize
                    );
                    if (pageTotal == 0) {
                        this.params.pageIndex = 1;
                    }
                    if (
                        this.params.pageIndex > pageTotal &&
                        this.params.pageIndex != 1
                    ) {
                        this.params.pageIndex = pageTotal;
                        this.getList();
                    } else {
                        this.workList = response.list;
                        this.total = response.total;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        handleSizeChange(val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.pageIndex = val;
            this.getList();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //批量修改状态/标签
        createBatchStatus(type) {
            var studentIds = JSON.parse(JSON.stringify(this.multipleSelection));
            if (studentIds.length == 0) {
                this.$message({
                    message: "请先选择学生",
                    type: "warning",
                });
                return;
            }
            var ids = [];
            studentIds.forEach(element => {
                ids.push(element.id);
            });
            if (type == 1) {
                if (this.batchStatus === "") {
                    this.$message({
                        message: "请选择状态",
                        type: "warning",
                    });
                    return;
                }
                this.$ajax
                    .post("user/student/my/batchEditStudyStatus", {
                        studyStatus: this.batchStatus,
                        ids: ids,
                    })
                    .then(res => {
                        this.$message({
                            message: "批量修改状态成功",
                            type: "success",
                        });
                        this.showBatchStatus = false;
                        this.getList();
                    });
            } else if (type == 2) {
                if (this.batchClass === "") {
                    this.$message({
                        message: "请选择标签",
                        type: "warning",
                    });
                    return;
                }
                this.$ajax
                    .post("user/student/my/editGroup", {
                        groupId: this.batchClass,
                        ids: ids,
                    })
                    .then(res => {
                        this.$message({
                            message: "批量修改标签成功",
                            type: "success",
                        });
                        this.showBatchClass = false;
                        this.getList();
                    });
            }
        },
        //重置
        resetBatch() {
            this.showBatch = !this.showBatch;
            this.checkedAll = false;

            this.$refs.multipleTable.clearSelection();
        },
        allCheck() {
            if (this.checkedAll) {
                this.workList.forEach((item, index) => {
                    this.$refs.multipleTable.toggleRowSelection(
                        this.workList[index],
                        true
                    );
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.loading = true;
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = JSON.parse(JSON.stringify(response.data));
            this.courseList.unshift({ name: "全部标签", id: -1 });
        });
        this.getList();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
};
</script>
<style lang="scss">
.poster .el-button {
    width: 326px;
    background: #5d5cd4;
    border-color: #5d5cd4;
}
</style>
<style lang="scss" scoped>
#qrcode {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 27px;
    top: 265px;
}
.poster {
    width: 370px;
    height: 538px;
    background-image: url("~@/assets/imgs/poster.png");
    background-size: cover;
    position: relative;
    padding-top: 432px;
    box-sizing: border-box;
}
.info_pop {
    img {
        height: 200px;
        width: 150px;
        margin-right: 20px;
    }
    div {
        font-size: 16px;
        + div {
            margin-top: 10px;
        }
    }
}
.search_part {
    > * {
        + * {
            margin-left: 7px;
        }
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
            width: 120px;
        }
        &:nth-child(4) {
            width: 220px;
        }
        &:nth-child(7) {
            width: 220px;
        }
    }
}
.manage {
    padding: 20px 30px 0 0;
    //   padding-top: 40px;
}
.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}
.no_data {
    text-align: center;
    .empty {
        margin-top: 60px;
        margin-bottom: 24px;
        i {
            font-size: 130px;
            color: #999;
        }
    }
    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}
.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}
</style>
